import LogoTypes from './types.js'

const LogoWhite = 'ucsc-logo-white.svg'
const LogoGray = 'ucsc-logo-gray.svg'
const LogoNoPayoff = 'ucsc-logo-white-no-payoff.png'
const LogoColor = 'ucsc-logo.svg'

export default {
  [LogoTypes.White]: {
    img: LogoWhite,
    width: '260',
    height: '40',
  },
  [LogoTypes.Gray]: {
    img: LogoGray,
    width: '260',
    height: '40',
  },
  [LogoTypes.NoPayoff]: {
    img: LogoNoPayoff,
    width: '260',
    height: '27',
  },
  [LogoTypes.Color]: {
    img: LogoColor,
    width: '260',
    height: '40',
  },
}
