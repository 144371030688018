var render = function render(){var _vm=this,_c=_vm._self._c;return _c('OCollapse',{class:[
    {
      'ucsc-accordion--borderBottomColor': _vm.isOpenLocal,
      'ucsc-accordion--noBorderTop': !_vm.borderTop,
      'ucsc-accordion--noBorderBottom': !_vm.borderBottom,
      'ucsc-accordion--noPadding': !_vm.padding,
    },
    _vm.classes,
  ],attrs:{"animation":"slide","open":_vm.isOpenLocal,"content-class":_vm.contentClass},on:{"update:open":function($event){_vm.isOpenLocal = $event}},scopedSlots:_vm._u([{key:"trigger",fn:function({ open }){return [(_vm.$slots.trigger || _vm.$scopedSlots.trigger)?_c('div',[_vm._t("trigger",null,{"accordionId":`${_vm._uid}`})],2):_c('div',{class:[
        'ucsc-accordion__header',
        {
          'hover:text-white': _vm.inFooter,
          'hover:text-gray': !_vm.inFooter,
        },
      ],attrs:{"role":"button","tabindex":"0","aria-labelledby":`Accordion-${_vm._uid}`,"aria-expanded":`${_vm.isOpenLocal}`,"aria-controls":`AccordionContent-${_vm._uid}`},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.isOpenLocal = !_vm.isOpenLocal}}},[_c('p',{staticClass:"font-bold text-lg",class:open ? `ucsc-accordion__text--${_vm.highlightedText}` : '',attrs:{"id":`Accordion-${_vm._uid}`}},[_vm._v("\n        "+_vm._s(_vm.heading)+"\n      ")]),_vm._v(" "),_c('span',{staticClass:"flex h-10 items-center"},[_c('SvgIcon',{staticClass:"ucsc-accordion__toggle-icon",attrs:{"name":open ? _vm.iconOpen : _vm.iconClose}})],1)])]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"ucsc-accordion__content",attrs:{"id":`AccordionContent-${_vm._uid}`,"role":"region","aria-labelledby":`Accordion-${_vm._uid}`}},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }